import React from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import IconNav from 'restaurant-v3/src/icons/nav.svg';
import IconClose from 'restaurant-v3/src/icons/close.svg';

type ToggleNavigationState = 'opening' | 'open' | 'closing' | 'closed';

type Props = {
    callbacks: {
        onClosed: () => void;
        onClosing: () => void;
        onOpen: () => void;
        onOpening: () => void;
    };
    transitionDurationMs: number;
    navigationState: ToggleNavigationState;
};

export const MobileToggleNavigationIcon: React.FC<Props> = ({
    callbacks,
    transitionDurationMs,
    navigationState,
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '1.5rem',
                height: '1.5rem',
            }}
        >
            {navigationState !== 'open' && (
                <Image
                    alt="navicon"
                    onClick={() => {
                        callbacks.onOpening();
                        setTimeout(() => {
                            callbacks.onOpen();
                        }, transitionDurationMs);
                    }}
                    src={IconNav}
                    style={{
                        cursor: 'pointer',
                        transitionProperty: 'opacity, transform',
                        transitionTimingFunction: 'linear',
                        position: 'absolute',
                        transitionDuration: `${transitionDurationMs / 1000}s`,
                        opacity: navigationState === 'closed' ? 1 : 0,
                        ...(navigationState === 'closed' ? {} : {
                            transform: 'rotate(-180deg)',
                        }),
                    }}
                />
            )}
            {navigationState !== 'closed' && (
                <Image
                    alt="navicon"
                    onClick={() => {
                        callbacks.onClosing();
                        setTimeout(() => {
                            callbacks.onClosed();
                        }, transitionDurationMs);
                    }}
                    src={IconClose}
                    style={{
                        cursor: 'pointer',
                        transitionProperty: 'opacity, transform',
                        transitionTimingFunction: 'linear',
                        position: 'absolute',
                        transitionDuration: `${transitionDurationMs / 1000}s`,
                        opacity: navigationState === 'open' ? 1 : 0,
                        ...(navigationState === 'open' ? {} : {
                            transform: 'rotate(-180deg)',
                        }),
                    }}
                />
            )}
        </Box>
    );
};
