import { PositiveIntegerSchema } from 'src/shared/types';

export const cssPxValues = {
    icons: {
        arrowLeftSize: PositiveIntegerSchema.parse(24),
        arrowRightSize: PositiveIntegerSchema.parse(24),
    },
    header: {
        logoSize: {
            mobile: PositiveIntegerSchema.parse(64),
            browser: PositiveIntegerSchema.parse(80),
        },
    },
    footer: {
        logoSize: PositiveIntegerSchema.parse(80),
    },
};
