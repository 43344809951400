import React from 'react';
import Box from '@mui/material/Box';

type VisualButtonVariant =
    | 'primary'
    | 'primaryInverted'
    | 'secondary';
    // Note - tertiary - NYI
    // | 'tertiary';

type Props = {
    variant: VisualButtonVariant;
    label: string;
};

export const VisualButton = ({ variant, label }: Props) => {
    return (
        <Box
            sx={{
                paddingY: 2,
                paddingX: 4,
                gap: 1,
                borderRadius: 2,
                fontWeight: 600,
                ...((function () {
                    switch (variant) {
                        case 'primary':
                            return {
                                bgcolor: 'neutral900.main',
                                color: 'white.main',
                                cursor: 'pointer',
                                transitionProperty: 'background-color color border',
                                transitionDuration: '200ms',
                                transitionTimingFunction: 'ease-in-out',
                                transitionDelay: '10ms',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                '&:hover': {
                                    bgcolor: 'white.main',
                                    color: 'neutral900.main',
                                    borderColor: 'neutral900.main',
                                },
                            };
                        case 'primaryInverted':
                            return {
                                transitionProperty: 'background-color color border',
                                transitionDuration: '200ms',
                                transitionTimingFunction: 'ease-in-out',
                                transitionDelay: '10ms',
                                borderColor: 'neutral200.main',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                '&:hover': {
                                    bgcolor: 'neutral900.main',
                                    color: 'white.main',
                                    borderColor: 'white.main',
                                },
                            };
                        case 'secondary':
                            return {
                                bgcolor: 'white.main',
                                '&:hover': {
                                    bgcolor: 'neutral100.main',
                                },
                            };
                        default:
                            return {};
                    }
                })()),
            }}
        >
            {label}
        </Box>
    );
};
