'use client';

import React, { useEffect, useRef, useState } from 'react';
import MuiLink from '@mui/material/Link';
import { useScript } from 'usehooks-ts';
import { useMediaQuery } from '@mui/material';
import { logger } from 'common/utils/logger';

import type { Theme } from '@mui/material';
import type { NonEmptyString } from 'src/shared/types';

type Props = {
    longitude: number;
    latitude: number;
    restaurantAddressString: NonEmptyString;
};

let m: any = undefined;

const setupMap = (mapElm: HTMLDivElement, SMap: any, {
    latitude,
    longitude,
    zoom,
}: {
    latitude: number;
    longitude: number;
    zoom: number;
}) => {
    const center = SMap.Coords.fromWGS84(longitude, latitude);
    m = new SMap(mapElm, center, zoom);
    m.addDefaultLayer(SMap.DEF_BASE).enable();

    const markerLayer = new SMap.Layer.Marker();
    const marker = new SMap.Marker(center);
    markerLayer.addMarker(marker);
    m.addLayer(markerLayer).enable();

    // Lock the map so that it cannot be moved
    m.lock();

    return () => {

        try {
            m.$destructor();

            m = null;
        } catch (err) {
            // @ts-expect-error
            logger.error(err);
        }
    };
};

export const Map: React.FC<Props> = ({
    longitude,
    latitude,
    restaurantAddressString,
}) => {
    const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const mapyCzZoom = 16;
    const qerkoMapRef = useRef<HTMLDivElement | null>(null);

    const loadingStatus = useScript('https://api.mapy.cz/loader.js', {
        removeOnUnmount: false,
    });
    const [SMap, setSMap] = useState(null);

    useEffect(() => {
        if (loadingStatus !== 'ready') {
            return;
        }

        (async () => {
            await new Promise((resolve, reject) => {
                try {
                    // @ts-expect-error
                    const Loader = window.Loader;
                    if (!Loader) {
                        throw new Error('Missing window.Loader');
                    }

                    Loader.async = true;
                    Loader.load(null, null, resolve);
                } catch (err) {
                    reject(err);
                }
            });

            // @ts-expect-error
            const SMap = window.SMap;
            if (!SMap) {
                throw new Error('Missing window.SMap');
            }

            setSMap(() => SMap);
        })();
    }, [loadingStatus]);

    useEffect(() => {
        if (qerkoMapRef.current === null || SMap === null) {
            return;
        }

        return setupMap(qerkoMapRef.current, SMap, {
            latitude,
            longitude,
            zoom: mapyCzZoom,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qerkoMapRef === null, SMap]);

    const alignment = (() => {
        if (isMobileScreen) {
            return {
                type: 'inheritFull',
            };
        }
        return {
            type: 'fixedSize',
        };
    })();
    return (
        <div style={{
            opacity: 1,
            ...(alignment.type === 'fixedSize' ? {
                height: 320,
                width: 320,
            } : {}),
            ...(alignment.type === 'inheritFull' ? {
                height: '100%',
                width: '100%',
            } : {}),
            position: 'relative',
            borderRadius: 8,
        }}
        >
            <MuiLink
                href={`https://en.mapy.cz/zakladni?x=${longitude}&y=${latitude}&z=${mapyCzZoom}&q=${encodeURIComponent(restaurantAddressString)}`}
            >
                <div ref={qerkoMapRef} style={{ height: '320px', borderRadius: 8 }} />
            </MuiLink>
        </div>
    );
};
