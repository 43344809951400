import { BooleanFromStringSchema, NonEmptyStringSchema, type NonEmptyString64 } from 'src/shared/types';
import { dailyMenuId, footerId } from 'restaurant-v3/src/constants/scrollableElementIds';

import { resolveReservationLink } from './reservationLink';

import type { useRestaurantInfoQuery } from 'restaurant-v3/src/hooks/queries/useRestaurantInfoQuery';
import type { NavigationType } from 'restaurant-v3/src/components/Layout/types';

type Props = {
    featureFlags: {
        lunchDailyMenu?: boolean;
        reservations?: boolean;
    };
    context: {
        restaurantDomain: NonEmptyString64;
        restaurantInfoResult: Extract<ReturnType<typeof useRestaurantInfoQuery>['data']['result'], { success: true }>['payload'];
    };
};

export const getHref = (to: string, restaurantDomain: NonEmptyString64) => `${BooleanFromStringSchema.catch(false).parse(process.env.NEXT_PUBLIC_NAVIGATION_PREPEND_RESTAURANT_DOMAIN) ? `/${restaurantDomain}` : ''}${to}`;

export const getNavigation = ({ featureFlags, context }: Props): NavigationType[] => {
    return [
        ...(
            featureFlags.lunchDailyMenu ? [
                {
                    browserButtonVariant: 'secondary' as NavigationType['browserButtonVariant'],
                    mobileNavVariant: 'link' as NavigationType['mobileNavVariant'],
                    label: NonEmptyStringSchema.parse('Denní Menu'),
                    to: getHref(`/#${dailyMenuId}`, context.restaurantDomain),
                },
            ] : []
        ),
        {
            browserButtonVariant: 'secondary',
            mobileNavVariant: 'link',
            label: NonEmptyStringSchema.parse('Menu a Nápoje'),
            to: getHref('/menu', context.restaurantDomain),
        },
        {
            browserButtonVariant: 'secondary',
            mobileNavVariant: 'link',
            label: NonEmptyStringSchema.parse('Kontakt'),
            to: NonEmptyStringSchema.parse(`#${footerId}`),
        },
        {
            browserButtonVariant: 'primary',
            mobileNavVariant: 'primaryButton',
            label: NonEmptyStringSchema.parse('Rezervovat Stůl'),
            to: resolveReservationLink(context.restaurantInfoResult),
        },
    ];
};
