'use client';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { getHref } from 'restaurant-v3/src/utils/navigation';
import { cssPxValues } from 'restaurant-v3/src/constants/cssPxValues';
import { Logo } from 'restaurant-v3/src/components/Logo';
import { MobileToggleNavigationIcon } from 'restaurant-v3/src/components/MobileToggleNavigationIcon';

import { MobileNavigation } from './MobileNavigation';

import type { HeaderProps } from './types';

type Props = HeaderProps;

export const HeaderMobile: React.FC<Props> = ({
    logoImageUrl,
    restaurantDomain,
    restaurantName,
    navigation,
}) => {
    const [navState, setNavState] = useState<Parameters<typeof MobileToggleNavigationIcon>[0]['navigationState']>('closed');
    const callbacks: Parameters<typeof MobileToggleNavigationIcon>[0]['callbacks'] = {
        onOpening: () => setNavState('opening'),
        onOpen: () => setNavState('open'),
        onClosing: () => setNavState('closing'),
        onClosed: () => setNavState('closed'),
    };
    const navOpenTransitionDurationMs = 500;
    return (
        <Box
            sx={{
                mt: -1,
                mb: 1,
                mx: 1,
                top: 0,
                left: 0,
                zIndex: 1000,
                bgcolor: 'white.main',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '8px 0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2.5,
                    }}
                >
                    {/* NOTE(samuel) remove this when stories are implemented */}
                    <MuiLink
                        href={getHref('/', restaurantDomain)}
                    >
                        <Logo
                            imageUrl={logoImageUrl}
                            size={cssPxValues.header.logoSize.mobile}
                            variant="circular"
                        />
                    </MuiLink>
                    <MuiLink
                        href={getHref('/', restaurantDomain)}
                    >
                        <Typography
                            sx={{
                                margin: 0,
                            }}
                            variant="h6"
                        >
                            {restaurantName}
                        </Typography>
                    </MuiLink>
                </Box>
                <MobileToggleNavigationIcon
                    callbacks={callbacks}
                    navigationState={navState}
                    transitionDurationMs={navOpenTransitionDurationMs}
                />
            </Box>
            <Box sx={{
                width: '100%',
                zIndex: 10,
                left: 0,
                paddingX: 2,
                backgroundColor: 'white.main',
            }}
            >
                <MobileNavigation
                    navigation={navigation}
                    navigationState={navState}
                    transitionDurationMs={navOpenTransitionDurationMs}
                />
            </Box>
        </Box>
    );
};
