import React from 'react';
import Image from 'next/image';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import IconNavNext from 'restaurant-v3/src/icons/nav-next.svg';
import { VisualButton } from 'restaurant-v3/src/components/VisualButton';

import type { MobileToggleNavigationIcon } from 'restaurant-v3/src/components/MobileToggleNavigationIcon';
import type { HeaderProps } from './types';

type Props = {
    navigation: HeaderProps['navigation'];
    navigationState: Parameters<typeof MobileToggleNavigationIcon>[0]['navigationState'];
    transitionDurationMs: number;
};

export const MobileNavigation: React.FC<Props> = ({ navigation, navigationState, transitionDurationMs }) => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                transitionProperty: 'all',
                transitionTimingFunction: 'ease-out',
                transitionDuration: `${transitionDurationMs}ms`,
                overflow: 'hidden',
                ...((function () {
                    switch (navigationState) {
                        case 'closing':
                        case 'closed':
                            return {
                                maxHeight: 0,
                            };
                        case 'opening':
                        case 'open':
                            return {
                                maxHeight: '256px',
                                paddingBottom: 2,
                            };
                    }
                })()),
            }}
        >
            {navigation.map(({ label, to, mobileNavVariant }) => (
                <Link href={to} key={to} legacyBehavior passHref>
                    <MuiLink>
                        {mobileNavVariant === 'link' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography>
                                    {label}
                                </Typography>
                                <Image
                                    alt="Nav Next Icon"
                                    src={IconNavNext}
                                />
                            </Box>
                        )}
                        {mobileNavVariant === 'primaryButton' && (
                            <VisualButton label={label} variant="primary" />
                        )}
                    </MuiLink>
                </Link>
            ))}
        </Box>
    );
};
